<template>
    <b-container fluid class="reports-savings-ratio-percentage-data-content-wrapper px-0 settings-tab">
        <b-row no-gutters>
            <b-col>
              <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
                <template v-slot:header>
                  <h2>{{ $t('common.header.header_menu.user_menu.settings') }}</h2>
                </template>
                <template v-if="allSettings.length > 0">
                  <h3>{{ $t('common.save_as_report') }}</h3>
                  <p>{{ $t('common.save_as_report_description') }}</p>
                </template>
                <CustomTabFilter
                  disableOk
                  :disableOkayButtonUnderFields="false"
                  ref="customTab"
                  :sections="sections"
                  :saveLabel="$t('common.save')"
                  :okayLabel="$t('common.okay_report_settings')"
                  :settingsData="allSettings"
                  :busyState="busySettingsState"
                  @submit="handleActiveTabs"
                  @delete="handleDelete"
                  @select="(value) => handleSelectSetting(value.id, value.content)"
                  @okay="(value) => handleSelectSetting(null, value)"
                >
                  <!--HEADING UNDER TABLE-->
                  <template #details>
                    <h3>{{ $t('common.save_settings_as_report') }}</h3>
                  </template>
                  <!--CUSTOM SLOT-->
                  <template #savedetails>
                    <h4>{{ $t('common.save_settings_as_report_2') }}</h4>
                    <p>{{ $t('common.save_settings_as_report_description_2') }}</p>
                  </template>
                  <!--CUSTOM SLOT END HERE-->
                </CustomTabFilter>
              </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import BalanceReportOverTimeSettings from '../BalanceReportOverTime/BalanceReportOverTimeSettings'
export default {
  name: 'AssetLiabilitySubtypesReportSettings',
  extends: BalanceReportOverTimeSettings
}
</script>

<style lang="scss" scoped>
.settings-tab {
    .main-gradient-content-card {
        top: -30px;
    }
}
</style>
